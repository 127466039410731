import './scss/app.scss';
const jquery = require('jquery');
window.$ = window.jQuery = jquery;
require('bootstrap');
const Plyr = require('plyr/dist/plyr.polyfilled');
require('slick-carousel');
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
require('zoom-vanilla.js');
import 'zoom-vanilla.js/dist/zoom.css';
require('objectFitPolyfill');
require('imagesloaded');

$(function () {

	// 🔹 nav line animation on dropdown opening
	$('.menu__item').on('show.bs.dropdown', function () {
		if (!$('.nav').hasClass('nav--open')) {
			$('.nav').addClass('nav--open');
		}
	});
	$('.menu__item').on('hide.bs.dropdown', function () {
		if ($('.nav').hasClass('nav--open')) {
			$('.nav').removeClass('nav--open');
		}
	});

	// 🔹 language selector dropdown animation
	$('.js-language-selector').on('click', function(e) {
		e.preventDefault();
		$('.language-selector__list').toggleClass('show');
	});

	// 🔹 mobile nav toggler
	$('.js-nav__toggler').on('click', function(e) {
		e.preventDefault();
		$(this).toggleClass('open');
		let navHeight = $('.nav').outerHeight();
		let winHeight = $(window).outerHeight();
		let wrapperHeight = winHeight - navHeight;
		$('.menu__wrapper').css('height', wrapperHeight+'px');
		$('.menu').css('min-height', wrapperHeight+'px');
		$('.menu__wrapper').toggleClass('show');
		$('.nav').toggleClass('open');
		$('body').toggleClass('no-scroll');
	});

	// 🔹 search close icon
	$('.js-search-dropdown__close').on('click', function(e) {
		e.preventDefault();
	});

	// 🔹 filter sidebar listener (mobile)
	$('.sidebar__title--toggle').on('click', function(e) {
		e.preventDefault();
		$(this).next('.sidebar__menu').toggleClass('show');
	});

	// 🔹 pump selection area click
	$('.pump-selection-cta').on('click', function(e) {
		window.open('https://www.pumpselection.eu', '_blank');
	});

	// 🔶 listener to window resize
	$(window).on('resize', function(e) {

		// 🔹 update mobile menu wrapper height
		if ($('body').css('padding-top') == '56px') {
			let navHeight = $('.nav').outerHeight();
			let winHeight = $(window).outerHeight();
			let wrapperHeight = winHeight - navHeight;
			$('.menu__wrapper').css('height', wrapperHeight+'px');
			$('.menu').css('min-height', wrapperHeight+'px');
		}
		else {
			$('.menu__wrapper').css('height', 'auto');
			$('.menu').css('min-height', 'auto');
		}

	});

	// ✒ single pages
	// 🏚 home
	// ==> polyfill for home video object-fit
	objectFitPolyfill($('.js-home-plyr'));
	// ==> home video
	new Plyr('.js-home-plyr', {
		controls: ['play-large'],
		clickToPlay: false,
		loop: {
			active: true
		},
		autoplay: true
	});
	// ==> home news slideshow
	$('.js-home-intro__carousel').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		prevArrow: $('.js-home-intro__carousel--prev'),
		nextArrow: $('.js-home-intro__carousel--next')
	});
	// ==> home map modal toggler
	$('.js-map-toggler').on('click', function(e) {
		e.preventDefault();
		$('.map-modal').removeClass('show');
		$(this).next('.map-modal').addClass('show');

	});
	// ==> home map modal closer
	$('.js-map-modal-close').on('click', function(e) {
		e.preventDefault();
		$('.map-modal').removeClass('show');
	});
	// ==> home map filter
	$('.js-filter-app').on('click', function(e) {
		e.preventDefault();
		var appIds = $(this).data('app');
		appIds = appIds.split('|');
		$('.js-map-toggler').removeClass('active');
		for (var i=0; i<appIds.length; i++) {
			$('.js-map-toggler[data-app='+appIds[i]+']').addClass('active');
		}
	});
	// 📌 base page
	// ==> header slider
	$('.js-header-slider').slick({
		fade: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		dots: true
	});
	// 📌 section page
	$('.js-gallery-page--zoom').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		asNavFor: '.js-gallery-page--thumbs'
	});
	$('.js-gallery-page--thumbs').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		asNavFor: '.js-gallery-page--zoom',
		dots: false,
		arrows: false,
		focusOnSelect: true
	});
	// ==> products carousel
	$('.js-products-carousel').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: $('.js-products-carousel__arrow--prev'),
		nextArrow: $('.js-products-carousel__arrow--next'),
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			  }
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});
	// ==> carousel gallery
	$('.js-carousel-gallery').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 5,
		slidesToScroll: 1,
		prevArrow: $('.js-carousel-gallery__arrow--prev'),
		nextArrow: $('.js-carousel-gallery__arrow--next')
	});
	// ==> carousel news
	$('.js-carousel-news').slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: $('.js-carousel-news__arrow--prev'),
		nextArrow: $('.js-carousel-news__arrow--next'),
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			  }
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			}
		]
	});


});